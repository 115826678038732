.video-stream {
    width: 100%;
    height: 100%;
    position: absolute !important;
    top: 0;
    right: 0;
    z-index: 1;
    pointer-events: none;
    & #h5live-video-stream {
        position: absolute;
        top: 0;
        left: 0;
    }
    &-loading {
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        background: transparent linear-gradient(180deg, rgba(29, 27, 45, 0.7019607843) 0%, rgba(29, 27, 45, 0.9019607843) 100%) 0% 0% no-repeat padding-box;
        & svg {
            width: 15vw;
            -webkit-animation: rotating 2s linear infinite;
            -moz-animation: rotating 2s linear infinite;
            -ms-animation: rotating 2s linear infinite;
            -o-animation: rotating 2s linear infinite;
            animation: rotating 2s linear infinite;
        }
    }
    & #h5live-video-stream {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
}

.tab {
    .video-stream {
        height: calc(468px * var(--device-pixel-ratio));
        top: calc(75px * var(--device-pixel-ratio));
        & #h5live-video-stream {
            object-fit: fill;
        }
    }
}

@media only screen and (max-width: 540px) {
    .video-stream {
        display: flex;
        flex-direction: column;
        height: 33.58vh;
        justify-content: flex-end;
        left: unset;
        margin-bottom: 1.7vh;
        margin-top: 0;
        position: fixed !important;
        right: unset;
        top: 68px;
        width: 100vw;
        z-index: 1;
        & #h5live-video-stream {
            object-fit: fill;
        }
    }
}