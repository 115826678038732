.cards {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: calc(224px * var(--device-pixel-ratio));
  width: calc(196px * var(--device-pixel-ratio));
  height: calc(260px * var(--device-pixel-ratio));
  left: calc(0px * var(--device-pixel-ratio));
  background: transparent linear-gradient(180deg, #1D1B2DB3 0%, #1D1B2DE6 100%) 0% 0% no-repeat padding-box;
  border: calc(1px * var(--device-pixel-ratio)) solid #87879480;
  border-left-color: transparent;
  border-radius: calc(0px * var(--device-pixel-ratio)) calc(8px * var(--device-pixel-ratio)) calc(8px * var(--device-pixel-ratio)) calc(0px * var(--device-pixel-ratio));
  padding: calc(20px * var(--device-pixel-ratio));
  &-element {
    & .title {
      text-align: right;
      font: calc(15px * var(--device-pixel-ratio))/calc(18px * var(--device-pixel-ratio)) "SF Pro Text Medium";
      letter-spacing:  calc(0.6px * var(--device-pixel-ratio));
      color: #FFFFFF;
      text-shadow: calc(1px * var(--device-pixel-ratio)) calc(1px * var(--device-pixel-ratio)) calc(2px * var(--device-pixel-ratio)) #000000;
      margin-bottom: calc(8px * var(--device-pixel-ratio));
      text-transform: uppercase;
    }
    &:nth-child(1) {
      padding-bottom: calc(20px * var(--device-pixel-ratio));
      border-bottom: calc(1px * var(--device-pixel-ratio)) solid rgba(135, 135, 148, 0.5);
    }
    &:nth-child(2) {
      padding-top: calc(20px * var(--device-pixel-ratio));
    }
  }
  &-list {
    list-style: none;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
    flex-direction: row;
    &-item {
      width: calc(46px * var(--device-pixel-ratio));
      height: calc(69px * var(--device-pixel-ratio));
      &:not(:last-child){
        margin-right: calc(10px * var(--device-pixel-ratio));
      }

      & .flip-card {
        width: 100%;
        height: 100%;
        perspective: calc(1000px * var(--device-pixel-ratio));
      }

      & .flip-card-inner {
        position: relative;
        width: 100%;
        height: 100%;
        transition: transform 0.6s;
        transform-style: preserve-3d;
      }

      & .flip-card.active .flip-card-inner {
        transform: rotateY(180deg);
      }

      & .flip-card-front, .flip-card-back {
        position: absolute;
        width: 100%;
        height: 100%;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
      }
      & .flip-card-front, & .flip-card-back {
        & img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      & .flip-card-back {
        transform: rotateY(180deg);
        & .cardIcon {
          height: 100%;
          font-size: calc(64px * var(--device-pixel-ratio));
          position: absolute;
          top: 0;
          box-shadow: none;
          background: transparent;
        }
      }
    }
  }
}

.middlePc {
  .cards {
    top: calc(160px * var(--device-pixel-ratio));
    width: calc(150px * var(--device-pixel-ratio));
    height: calc(204px * var(--device-pixel-ratio));
    padding: calc(16px * var(--device-pixel-ratio));
    &-element {
      & .title {
        font: bold calc(12px * var(--device-pixel-ratio))/calc(14px * var(--device-pixel-ratio)) "SF Pro Text Bold";
        letter-spacing:  calc(0.48px * var(--device-pixel-ratio));
        margin-bottom: calc(6px * var(--device-pixel-ratio));
      }
      &:nth-child(1) {
        padding-bottom: calc(16px * var(--device-pixel-ratio));
      }
      &:nth-child(2) {
        padding-top: calc(16px * var(--device-pixel-ratio));
      }
    }
    &-list {
      &-item {
        width: calc(34px * var(--device-pixel-ratio));
        height: calc(50px * var(--device-pixel-ratio));
        &:not(:last-child){
          margin-right: calc(8px * var(--device-pixel-ratio));
        }

        & .flip-card-back {
          & .cardIcon {
            font-size: calc(50px * var(--device-pixel-ratio));
          }
        }
      }
    }
  }
}

.smallPc {
  .cards {
    top: calc(224px * var(--device-pixel-ratio));
    width: calc(150px * var(--device-pixel-ratio));
    height: calc(204px * var(--device-pixel-ratio));
    padding: calc(16px * var(--device-pixel-ratio));
    &-element {
      & .title {
        font: calc(12px * var(--device-pixel-ratio))/calc(14px * var(--device-pixel-ratio)) "SF Pro Text Medium";
        letter-spacing:  calc(0.48px * var(--device-pixel-ratio));
        margin-bottom: calc(6px * var(--device-pixel-ratio));
      }
      &:nth-child(1) {
        padding-bottom: calc(16px * var(--device-pixel-ratio));
      }
      &:nth-child(2) {
        padding-top: calc(16px * var(--device-pixel-ratio));
      }
    }
    &-list {
      &-item {
        width: calc(34px * var(--device-pixel-ratio));
        height: calc(50px * var(--device-pixel-ratio));

        &:not(:last-child) {
          margin-right: calc(8px * var(--device-pixel-ratio));
        }

        & .flip-card-back {
          & .cardIcon {
            font-size: calc(48px * var(--device-pixel-ratio));
          }
        }
      }
    }
  }
}

.tab {
  .cards {
    display: flex;
    flex-direction: row;
    position: unset;
    width: calc(240px * var(--device-pixel-ratio));
    height: calc(92px * var(--device-pixel-ratio));
    padding: calc(16px * var(--device-pixel-ratio));
    &-element {
      & .title {
        text-align: center;
        font: calc(12px * var(--device-pixel-ratio))/calc(14px * var(--device-pixel-ratio)) "SF Pro Text Medium";
        letter-spacing:  calc(0.48px * var(--device-pixel-ratio));
        margin-bottom: calc(6px * var(--device-pixel-ratio));
      }
      &:nth-child(1) {
        padding-right: calc(12px * var(--device-pixel-ratio));
        margin-right: calc(12px * var(--device-pixel-ratio));
        border-right: calc(1px * var(--device-pixel-ratio)) solid rgba(135, 135, 148, 0.5);
        border-bottom-color: transparent;
      }
      &:nth-child(2) {
        padding-top: calc(0px * var(--device-pixel-ratio));
      }
    }
    &-list {
      &-item {
        width: calc(28px * var(--device-pixel-ratio));
        height: calc(40px * var(--device-pixel-ratio));
        &:not(:last-child){
          margin-right: calc(4px * var(--device-pixel-ratio));
        }
        & .flip-card-back {
          & .cardIcon {
            font-size: calc(40px * var(--device-pixel-ratio));
            position: absolute;
            top: 0;
            box-shadow: none;
            background: transparent;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 540px) {
  .cards {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 23.689vh;
    width: 100vw;
    height: 60px;
    left: 0;
    background: transparent;
    border-color: transparent;
    padding: 0;
    &-element {
      width: 112px;
      height: 100%;
      background: transparent linear-gradient(180deg, #1D1B2DB3 0%, #1D1B2DE6 100%) 0% 0% no-repeat padding-box;
      border: 1px solid #87879480;
      border-radius: 0px 6px 6px 0px;
      &:nth-child(1) {
        border-left-color: transparent;
        padding: 10px;
      }
      &:nth-child(2) {
        border-radius: 6px 0px 0px 6px;
        border-right-color: transparent;
        padding: 10px;
      }
      & .title {
        display: none;
      }
    }
    &-list {
      &-item {
        width: 28px;
        height: 40px;
        &:not(:last-child){
          margin-right: calc(4px * var(--device-pixel-ratio));
        }
        & .flip-card-back {

          & .cardIcon {

            font-size: calc(40px * var(--device-pixel-ratio));
          }
        }
      }
    }
  }
}