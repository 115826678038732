.board {
  perspective: calc(929px * var(--device-pixel-ratio));
  width: calc(788px * var(--device-pixel-ratio));
  margin: 0 calc(150px * var(--device-pixel-ratio)) calc(20px * var(--device-pixel-ratio)) calc(150px * var(--device-pixel-ratio));
  position: relative;
  height: calc(160px * var(--device-pixel-ratio));

  &-info {
    transition: transform 0.5s, margin 0.5s;
    transform: rotate3d(0, 0, 0, 0deg);
    transform-origin: bottom;

    &.threeD {
      transform: rotate3d(-10, 0, 0, -45deg);
      transform-origin: bottom;
    }

    &-sides {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: calc(54px * var(--device-pixel-ratio));
      margin-bottom: calc(-20px * var(--device-pixel-ratio));

      & .title {
        font: 700 calc(23px * var(--device-pixel-ratio))/calc(44px * var(--device-pixel-ratio)) "SF Pro Text Light";
        letter-spacing: calc(1.48px * var(--device-pixel-ratio));
        color: #FFFFFF;
        text-transform: uppercase;
        background: transparent linear-gradient(180deg, rgba(29, 27, 45, 0.7019607843) 0%, rgba(29, 27, 45, 0.9019607843) 100%) 0% 0% no-repeat padding-box;
        border: calc(1px * var(--device-pixel-ratio)) solid rgba(135, 135, 148, 0.5);
        border-radius: 8px;
        padding: calc(0px * var(--device-pixel-ratio)) calc(10px * var(--device-pixel-ratio));
      }
    }

    .book-show-bottom {
      position: absolute;
      width: 100%;
      bottom: 0;

      .playera-pair-plus-book {
        background: transparent linear-gradient(180deg, rgba(29, 27, 45, .702), rgba(29, 27, 45, .902)) 0 0 no-repeat padding-box;
        border-radius: 12px;
        min-width: calc(64px*var(--device-pixel-ratio));
        padding: calc(4px*var(--device-pixel-ratio)) calc(14px*var(--device-pixel-ratio));
        text-align: center;
        color: #fff;
        position: absolute;
        top: -35px;
        left: 5%;
        font-size: calc(12px * var(--device-pixel-ratio));

        &.green {
          color: rgba(50, 215, 75, 1);
        }
      }

      .playerb-pair-plus-book {
        background: transparent linear-gradient(180deg, rgba(29, 27, 45, .702), rgba(29, 27, 45, .902)) 0 0 no-repeat padding-box;
        border-radius: 12px;
        min-width: calc(64px*var(--device-pixel-ratio));
        padding: calc(4px*var(--device-pixel-ratio)) calc(14px*var(--device-pixel-ratio));
        text-align: center;
        color: #fff;
        position: absolute;
        top: -35px;
        right: 5%;
        font-size: calc(12px * var(--device-pixel-ratio));

        &.green {
          color: rgba(50, 215, 75, 1);
        }
      }
    }
  }

  &-svg {
    &.smallBoard {
      display: none;
    }

    &-item {
      cursor: pointer;

      &.disabled {
        pointer-events: none;

        opacity: 0.4;
        // &.lay {
        //   opacity: 0.4;
        // }

        // &.back {
        //   opacity: 0.4;
        // }
      }

      &:hover {
        &.lay {
          & g {
            stroke: #faa9ba;
            fill: #faa9ba;
          }
        }

        &.back {
          & g {
            stroke: #72bbef;
            fill: #72bbef;
          }
        }

        &.pair {
          & g {
            stroke: #eaeaec;
            fill: #eaeaec;
          }
        }
      }

      &.active,
      &:active {
        &.lay {
          & g {
            stroke: #faa9ba;
            fill: #faa9ba;
          }
        }

        &.back {
          & g {
            stroke: #72bbef;
            fill: #72bbef;
          }
        }

        &.pair {
          & g {
            stroke: #eaeaec;
            fill: #eaeaec;
          }
        }
      }
    }
  }
}

.middlePc {
  .board {
    width: calc(710px * var(--device-pixel-ratio));
    margin: 0 calc(106px * var(--device-pixel-ratio)) calc(16px * var(--device-pixel-ratio)) calc(106px * var(--device-pixel-ratio));
    height: calc(100px * var(--device-pixel-ratio));

    &-info {
      &-sides {
        height: calc(40px * var(--device-pixel-ratio));
        margin-bottom: calc(-20px * var(--device-pixel-ratio));

        & .title {
          font: 700 calc(18px * var(--device-pixel-ratio))/calc(30px * var(--device-pixel-ratio)) "SF Pro Text Light";
          letter-spacing: calc(1.28px * var(--device-pixel-ratio));
          padding: calc(0px * var(--device-pixel-ratio)) calc(5px * var(--device-pixel-ratio));
        }
      }
    }
  }
}

.smallPc {
  .board {
    width: calc(710px * var(--device-pixel-ratio));
    height: calc(120px * var(--device-pixel-ratio));
    margin: 0 calc(11px * var(--device-pixel-ratio)) calc(16px * var(--device-pixel-ratio)) calc(11px * var(--device-pixel-ratio));

    &-info {
      &-sides {
        height: calc(40px * var(--device-pixel-ratio));
        margin-bottom: calc(-20px * var(--device-pixel-ratio));

        & .title {
          font: 700 calc(19px * var(--device-pixel-ratio))/calc(20px * var(--device-pixel-ratio)) "SF Pro Text Light";
          letter-spacing: calc(1.28px * var(--device-pixel-ratio));
          padding: calc(5px * var(--device-pixel-ratio)) calc(5px * var(--device-pixel-ratio));
        }
      }
    }

    &-svg {
      &.smallBoard {
        display: none;
      }
    }
  }
}

.tab {
  .board {
    order: 1;
    width: calc(710px * var(--device-pixel-ratio));
    margin: 0 auto;
    height: calc(127px * var(--device-pixel-ratio));

    &-info {
      &-sides {
        height: calc(50px * var(--device-pixel-ratio));
        margin-bottom: calc(-25px * var(--device-pixel-ratio));

        & .title {
          font: 700 calc(21px * var(--device-pixel-ratio))/calc(30px * var(--device-pixel-ratio)) "SF Pro Text Light";
          letter-spacing: calc(1.28px * var(--device-pixel-ratio));
          padding: calc(2px * var(--device-pixel-ratio)) calc(5px * var(--device-pixel-ratio));
        }
      }
    }

    &-svg {
      &.smallBoard {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: 540px) {
  .board {
    width: 89.066vw;
    order: 1;
    margin: 0 auto;
    height: auto;
    max-width: 50.07vh;
    max-height: 21.33vw;

    &-info {
      &.threeD {
        transform: rotate3d(0, 0, 0, 0deg);
      }

      &-sides {
        margin-bottom: 1.19vh;
        height: 2.998vh;
        position: absolute;
        top: -7vw;

        & .title {
          font: 900 2.53vw/2.998vh "SF Pro Text Light";
          letter-spacing: 0.181vw;
          margin-bottom: -1.5vh;
        }
      }

      .book-show-bottom {
        .playera-pair-plus-book {
          min-width: calc(40px * var(--device-pixel-ratio));
          padding: calc(2px * var(--device-pixel-ratio)) calc(6px * var(--device-pixel-ratio));
          font-size: calc(9px * var(--device-pixel-ratio));
          left: 19%;
          top: unset;
          bottom: -10px;
        }

        .playerb-pair-plus-book {
          min-width: calc(40px * var(--device-pixel-ratio));
          padding: calc(2px * var(--device-pixel-ratio)) calc(6px * var(--device-pixel-ratio));
          font-size: calc(9px * var(--device-pixel-ratio));
          right: 19%;
          top: unset;
          bottom: -10px;
        }
      }
    }

    &-svg {
      width: 100%;
      height: 100%;

      &.smallBoard {
        display: block;
      }

      &.largeBoard {
        display: none;
      }
    }
  }
}