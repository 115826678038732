.load {
  width: calc(500px * var(--device-pixel-ratio));
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: calc(24px * var(--device-pixel-ratio));
  position: absolute;
  bottom: calc(8px * var(--device-pixel-ratio));
  left: 50%;
  transform: translateX(-50%);
  transition: bottom 0.5s ease-in-out;
  pointer-events: none;
  &-info {
    width: calc(50% - calc(6px * var(--device-pixel-ratio)));
    font: Bold calc(14px * var(--device-pixel-ratio))/calc(16px * var(--device-pixel-ratio)) "SF Pro Text Bold";
    letter-spacing: calc(0.56px * var(--device-pixel-ratio));
    text-shadow: calc(1px * var(--device-pixel-ratio)) calc(1px * var(--device-pixel-ratio)) calc(2px * var(--device-pixel-ratio)) rgba(0, 0, 0, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    &-box {
      background: transparent linear-gradient(180deg, #1D1B2DB3 0%, #1D1B2DE6 100%) 0% 0% no-repeat padding-box;
      border-radius: 12px;
      min-width: calc(64px * var(--device-pixel-ratio));
      padding: calc(4px * var(--device-pixel-ratio))  calc(14px * var(--device-pixel-ratio)) ;
      text-align: center;
    }
    &.green {
      color: rgba(50, 215, 75, 1);
    }
    &.red {
      color: rgba(255, 69, 58, 1);
    }
  }
}

.middlePc {
  .load {
    width: calc(456px * var(--device-pixel-ratio));
    height: calc(18px * var(--device-pixel-ratio));
    bottom: calc(8px * var(--device-pixel-ratio));
    &-info {
      width: calc(50% - calc(5px * var(--device-pixel-ratio)));
      font: Bold calc(12px * var(--device-pixel-ratio))/calc(14px * var(--device-pixel-ratio)) "SF Pro Text Bold";
      letter-spacing: calc(0.48px * var(--device-pixel-ratio));
      &-box {
        min-width: calc(50px * var(--device-pixel-ratio));
        padding: calc(2px * var(--device-pixel-ratio))  calc(9px * var(--device-pixel-ratio)) ;
      }
    }
  }
}

.smallPc {
  .load {
    width: calc(456px * var(--device-pixel-ratio));
    height: calc(18px * var(--device-pixel-ratio));
    bottom: calc(8px * var(--device-pixel-ratio));
    &-info {
      width: calc(50% - calc(5px * var(--device-pixel-ratio)));
      font: Bold calc(11px * var(--device-pixel-ratio))/calc(13px * var(--device-pixel-ratio)) "SF Pro Text Bold";
      letter-spacing: calc(0.44px * var(--device-pixel-ratio));
      &-box {
        min-width: calc(50px * var(--device-pixel-ratio));
        padding: calc(3px * var(--device-pixel-ratio))  calc(13px * var(--device-pixel-ratio)) ;
      }
    }
  }
}

.tab {
  .load {
    width: calc(456px * var(--device-pixel-ratio));
    height: calc(18px * var(--device-pixel-ratio));
    bottom: calc(16px * var(--device-pixel-ratio));
    &.threeD {
      bottom: calc(8px * var(--device-pixel-ratio));
    }

    &-info {
      width: calc(50% - calc(5px * var(--device-pixel-ratio)));
      font: Bold calc(11px * var(--device-pixel-ratio))/calc(13px * var(--device-pixel-ratio)) "SF Pro Text Bold";
      letter-spacing: calc(0.44px * var(--device-pixel-ratio));
      &-box {
        min-width: calc(50px * var(--device-pixel-ratio));
        padding: calc(3px * var(--device-pixel-ratio))  calc(13px * var(--device-pixel-ratio)) ;
      }
    }
  }
}

@media only screen and (max-width: 540px) {
  .load {
    width: 100%;
    margin: 0.599vh 0 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: unset;
    top: -2vh;

    &-info {
      font: bold 2.66vw/1.799vh "SF Pro Text Bold";
      letter-spacing: 0.1066vw;
      margin: 0;
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}